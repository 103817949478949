// 神策埋点 初始化神策
const sensors = require('sa-sdk-javascript');
sensors.init({
  server_url: 'https://sensors.lianxiaowei.com/sa?project=kaduofen', // 数据接收地址（小微）
  // server_url: 'https://sadata.smyfinancial.com/sa?project=production', // 数据接收地址（省呗）
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'image',
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'default',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'not_collect',
  },
});
// 设置公共属性埋点
sensors.registerPage({
  envirType: process.env.NODE_ENV === 'production' ? 'prd' : 'dev',
});
// 自动采集事件埋点
sensors.quick('autoTrack', {
  platform: 'h5',
});

export default sensors;
