import type { objType } from '@/types';
import { ls } from '../storage';

export default {
  userInfo: {
    set(value: objType) {
      return ls.set('user.info', value);
    },
    get() {
      return ls.get('user.info');
    },
    remove() {
      return ls.remove('user.info');
    },
  },
  sendLoginCodePhone: {
    set(value: string) {
      return ls.set('user.loginCodePhone', value);
    },
    get() {
      return ls.get('user.loginCodePhone');
    },
    remove() {
      return ls.remove('user.loginCodePhone');
    },
  },
  sendLoginCodeId: {
    set(value: string) {
      return ls.set('user.sendLoginCodeId', value);
    },
    get() {
      return ls.get('user.sendLoginCodeId');
    },
    remove() {
      return ls.remove('user.sendLoginCodeId');
    },
  },
};
