import { showToast, showLoadingToast, closeToast, showNotify, showDialog, showConfirmDialog } from 'vant';

export default {
  // 消息提示
  msg(content, options = {}) {
    showToast({ message: content, duration: 5 * 1000, type: 'text', ...options });
  },
  // 错误消息
  msgError(content, options = {}) {
    showToast({ message: content, duration: 5 * 1000, type: 'fail', ...options });
  },
  // 成功消息
  msgSuccess(content, options = {}) {
    showToast({ message: content, duration: 5 * 1000, type: 'success', ...options });
  },
  // 警告消息
  msgWarning(content, options = {}) {
    showToast({ message: content, duration: 5 * 1000, type: 'fail', ...options });
  },
  // 弹出提示
  alert(content, title = '温馨提示', options = {}) {
    return showDialog({ message: content, title, ...options });
  },
  // 错误提示
  alertError(content, title = '失败', options = {}) {
    return showDialog({ message: content, title, ...options });
  },
  // 成功提示
  alertSuccess(content, title = '成功', options = {}) {
    return showDialog({ message: content, title, ...options });
  },
  // 警告提示
  alertWarning(content, title = '警告', options = {}) {
    return showDialog({ message: content, title, ...options });
  },
  // 通知提示
  notify(content, options = {}) {
    showNotify({ message: content, duration: 5 * 1000, type: 'primary', ...options });
  },
  // 错误通知
  notifyError(content, options = {}) {
    showNotify({ message: content, duration: 5 * 1000, type: 'danger', ...options });
  },
  // 成功通知
  notifySuccess(content, options = {}) {
    showNotify({ message: content, duration: 5 * 1000, type: 'success', ...options });
  },
  // 警告通知
  notifyWarning(content, options = {}) {
    showNotify({ message: content, duration: 5 * 1000, type: 'warning', ...options });
  },
  // 确认窗体
  confirm(content, title = '温馨提示', options = {}) {
    return showConfirmDialog({ message: content, title, ...options });
  },
  // 确认窗体 警告
  confirmWarning(content, title = '警告', options = {}) {
    return showConfirmDialog({ message: content, title, ...options });
  },
  // 打开遮罩层
  loading(content, options = {}) {
    showLoadingToast({ message: content, duration: 5 * 1000, type: 'loading', ...options });
  },
  // 关闭遮罩层
  closeLoading() {
    closeToast();
  },
};
