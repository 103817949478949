import { ls } from '../storage';

export default {
  token: {
    set(value: string) {
      return ls.set('global.token', value);
    },
    get() {
      return ls.get('global.token');
    },
    remove() {
      return ls.remove('global.token');
    },
  },
  // 用户的code
  wechatWebAppCode: {
    set(value: string) {
      return ls.set('global.code', value);
    },
    get() {
      return ls.get('global.code');
    },
    remove() {
      return ls.remove('global.code');
    },
  },
  userId: {
    set(value: string) {
      return ls.set('global.userId', value);
    },
    get() {
      return ls.get('global.userId');
    },
    remove() {
      return ls.remove('global.userId');
    },
  },
  openid: {
    set(value: string) {
      return ls.set('global.openid', value);
    },
    get() {
      return ls.get('global.openid');
    },
    remove() {
      return ls.remove('global.openid');
    },
  },
  globalQuestionId: {
    set(value: number) {
      return ls.set('global.globalQuestionId', value);
    },
    get() {
      return ls.get('global.globalQuestionId');
    },
    remove() {
      return ls.remove('global.globalQuestionId');
    },
  },
  globalChatId: {
    set(value: string) {
      return ls.set('global.globalChatId', value);
    },
    get() {
      return ls.get('global.globalChatId');
    },
    remove() {
      return ls.remove('global.globalChatId');
    },
  },
  islogout: {
    set(value: string) {
      return ls.set('global.islogout', value);
    },
    get() {
      return ls.get('global.islogout');
    },
    remove() {
      return ls.remove('global.islogout');
    },
  },
  wechatUserInfo: {
    set(value: string) {
      return ls.set('global.wechatUserInfo', value);
    },
    get() {
      return ls.get('global.wechatUserInfo');
    },
    remove() {
      return ls.remove('global.wechatUserInfo');
    },
  },
  isOpen: {
    set(value: string) {
      return ls.set('global.isOpen', value);
    },
    get() {
      return ls.get('global.isOpen');
    },
    remove() {
      return ls.remove('global.isOpen');
    },
  },
  dialogue: {
    set(value: string) {
      return ls.set('global.dialogue', value);
    },
    get() {
      return ls.get('global.dialogue');
    },
    remove() {
      return ls.remove('global.dialogue');
    },
  },
  dialogLists: {
    set(value: string) {
      return ls.set('global.dialogLists', value);
    },
    get() {
      return ls.get('global.dialogLists');
    },
    remove() {
      return ls.remove('global.dialogLists');
    },
  },
  promptForm: {
    set(value: string) {
      return ls.set('global.promptForm', value);
    },
    get() {
      return ls.get('global.promptForm');
    },
    remove() {
      return ls.remove('global.promptForm');
    },
  },
  creatorForm: {
    set(value: string) {
      return ls.set('global.creatorForm', value);
    },
    get() {
      return ls.get('global.creatorForm');
    },
    remove() {
      return ls.remove('global.creatorForm');
    },
  },
  promptFirstForm: {
    set(value: string) {
      return ls.set('global.promptFirstForm', value);
    },
    get() {
      return ls.get('global.promptFirstForm');
    },
    remove() {
      return ls.remove('global.promptFirstForm');
    },
  },
  isRefresh: {
    set(value: string) {
      return ls.set('global.isRefresh', value);
    },
    get() {
      return ls.get('global.isRefresh');
    },
    remove() {
      return ls.remove('global.isRefresh');
    },
  },
  apiType: {
    set(value: number) {
      return ls.set('global.apiType', value);
    },
    get() {
      return ls.get('global.apiType');
    },
    remove() {
      return ls.remove('global.apiType');
    },
  },
  userInfo: {
    set(value: string) {
      return ls.set('global.userInfo', value);
    },
    get() {
      return ls.get('global.userInfo');
    },
    remove() {
      return ls.remove('global.userInfo');
    },
  },
};
