import type { ChatQuestionType } from '@/types/index';
import { ls } from '../storage';

export default {
  questionId: {
    set(key: string, value: number) {
      return ls.set(`chat.questionId.${key}`, value);
    },
    get(key: string) {
      return ls.get(`chat.questionId.${key}`);
    },
    remove(key: string) {
      return ls.remove(`chat.questionId.${key}`);
    },
  },
  type: {
    set(value: string) {
      return ls.set('chat.type', value);
    },
    get() {
      return ls.get('chat.type');
    },
    remove() {
      return ls.remove('chat.type');
    },
  },
  setId: {
    set(value: string) {
      return ls.set('chat.setId', value);
    },
    get() {
      return ls.get('chat.setId');
    },
    remove() {
      return ls.remove('chat.setId');
    },
  },
  close: {
    set(value: number) {
      return ls.set('chat.close', value);
    },
    get() {
      return ls.get('chat.close');
    },
    remove() {
      return ls.remove('chat.close');
    },
  },
  map: {
    set(value: ChatQuestionType) {
      return ls.set('chat.map', value);
    },
    get() {
      return ls.get('chat.map');
    },
    remove() {
      return ls.remove('chat.map');
    },
  },
};
