import type { RouteRecordRaw } from 'vue-router';
import { isMobile } from '@/utils/index';
const device = 'PC';

// eslint-disable-next-line import/no-mutable-exports
let routes: Array<RouteRecordRaw> = [];

if (isMobile()) {
  routes = [
    {
      path: '/',
      name: 'Index',
      meta: { title: '首页' },
      redirect: '/home',
      component: () => import('@/pages/layout/index.vue'),
      children: [
        {
          path: '/home',
          name: 'Home',
          meta: { title: '首页', keepAlive: true },
          component: () => import('@/pages/home/index.vue'),
        },
        {
          path: '/community',
          name: 'Community',
          meta: { title: 'Prompt社区', keepAlive: true },
          component: () => import('@/pages/community/index.vue'),
        },
        {
          path: '/session-list',
          name: 'SessionList',
          meta: { title: '会话列表', keepAlive: true },
          component: () => import('@/pages/session-list/index.vue'),
        },
        {
          path: '/mime',
          name: 'Mime',
          meta: { title: '我的', keepAlive: true },
          component: () => import('@/pages/mime/index.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      meta: { title: '登录' },
      component: () => import('@/pages/login/index.vue'),
    },
    {
      path: '/chat',
      name: 'Chat',
      meta: { title: '小壹AI' },
      component: () => import('@/pages/chat/index.vue'),
    },
    {
      path: '/coreatorPermissions',
      name: 'CoreatorPermissions',
      meta: { title: '创作者权限申请' },
      component: () => import('@/pages/coreatorPermissions/index.vue'),
    },
    {
      path: '/privacyManage',
      name: 'PrivacyManage',
      meta: { title: '隐私管理' },
      component: () => import('@/pages/privacy/index.vue'),
    },
    {
      path: '/coreatorSuccess',
      name: 'CoreatorSuccess',
      meta: { title: '创作者权限申请' },
      component: () => import('@/pages/coreatorPermissions/success.vue'),
    },
    {
      path: '/coreatorSpecification',
      name: 'CoreatorSpecification',
      meta: { title: '壹词万物创作者社区规范' },
      component: () => import('@/pages/agreement/index.vue'),
    },
    {
      path: '/registerAgreement',
      name: 'RegisterAgreement',
      meta: { title: '壹词万物平台注册服务协议' },
      component: () => import('@/pages/agreement/register.vue'),
    },
    {
      path: '/privacyAgreement',
      name: 'PrivacyAgreement',
      meta: { title: '壹词万物隐私政策' },
      component: () => import('@/pages/agreement/privacy.vue'),
    },
    {
      path: '/promptSuccess',
      name: 'PromptSuccess',
      meta: { title: 'prompt工作台' },
      component: () => import('@/pages/community/prompt/success.vue'),
    },
    {
      path: '/isPrompt',
      name: 'IsPrompt',
      meta: { title: 'prompt社区' },
      component: () => import('@/pages/community/isPrompt/index.vue'),
    },
    {
      path: '/createPrompt',
      name: 'CreatePrompt',
      meta: { title: 'prompt社区' },
      component: () => import('@/pages/community/createPrompt/index.vue'),
    },
    {
      path: '/prompt',
      name: 'Prompt',
      meta: { title: '调试台' },
      component: () => import('@/pages/community/prompt/index.vue'),
    },
    {
      path: '/pc-recharge',
      name: 'PcRecharge',
      meta: { title: '充值' },
      component: () => import('@/pages/pcRecharge/index.vue'),
    },
    {
      path: '/prompt-chat',
      name: 'PromptChat',
      component: () => import('@/pages/community/chat/index.vue'),
    },
    {
      path: '/prompt-set',
      name: 'PromptSet',
      meta: { title: 'Prompt设置' },
      component: () => import('@/pages/community/prompt/set.vue'),
    },
    {
      path: '/album',
      name: 'Album',
      meta: { title: '相册' },
      component: () => import('@/pages/album/index.vue'),
    },
    {
      path: '/recharge',
      name: 'Recharge',
      component: () => import('@/pages/recharge/index.vue'),
      meta: { title: '充值', activeIndex: 2 },
    },
    {
      path: '/recharge/success',
      name: 'RechargeSuccess',
      component: () => import('@/pages/recharge/success.vue'),
      meta: { title: '充值完成', activeIndex: 2 },
    },
    // 系统消息
    {
      path: '/systemNessage',
      name: 'SystemNessage',
      component: () => import('@/pages/systemMessage/index.vue'),
      meta: { title: '系统消息' },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/pages/common/comUnfound.vue'),
      meta: { title: '404' },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ];
} else {
  routes = [
    {
      path: '/',
      name: 'Index',
      meta: { title: '首页' },
      redirect: '/home',
      component: () => import(`@/pages/layout/index${device}.vue`),
      children: [
        {
          path: '/home',
          name: 'Home',
          meta: { title: '首页', keepAlive: true },
          component: () => import(`@/pages/index/index.vue`),
        },
        {
          path: '/community',
          name: 'Community',
          meta: { title: '好胖社区', keepAlive: true },
          component: () => import(`@/pages/community/index${device}.vue`),
        },
        {
          path: '/prompt-set',
          name: 'PromptSet',
          meta: { title: 'Prompt设置', keepAlive: true },
          component: () => import(`@/pages/community/prompt/set${device}.vue`),
        },
        {
          path: '/session-list',
          name: 'SessionList',
          meta: { title: '会话列表', keepAlive: true },
          component: () => import(`@/pages/session-list/index${device}.vue`),
        },
        {
          path: '/mime',
          name: 'Mime',
          meta: { title: '我的', keepAlive: true },
          component: () => import('@/pages/mime/index.vue'),
        },
        {
          path: '/myElectricity',
          name: 'MyElectricity',
          meta: { title: '我的', keepAlive: true },
          component: () => import('@/pages/myElectricity/index.vue'),
        },
        {
          path: '/coreatorPermissions',
          name: 'CoreatorPermissions',
          meta: { title: '创作者权限申请' },
          component: () => import('@/pages/coreatorPermissions/indexPC.vue'),
        },
        {
          path: '/chat',
          name: 'Chat',
          meta: { title: '小壹AI' },
          component: () => import(`@/pages/chat/index${device}.vue`),
        },
        {
          path: '/dialogueList',
          name: 'DialogueList',
          component: () => import('@/pages/dialogueList/index.vue'),
          meta: { title: '会话', activeIndex: 2 },
        },
        {
          path: '/isPrompt',
          name: 'IsPrompt',
          meta: { title: 'prompt社区' },
          component: () => import('@/pages/community/isPrompt/index.vue'),
        },
        {
          path: '/createPrompt',
          name: 'CreatePrompt',
          meta: { title: 'prompt社区' },
          component: () => import('@/pages/community/createPrompt/index.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: '登录',
      redirect: '/home',
    },
    {
      path: '/registerAgreement',
      name: 'RegisterAgreement',
      meta: { title: '壹词万物平台注册服务协议' },
      component: () => import('@/pages/agreement/register.vue'),
    },
    {
      path: '/privacyAgreement',
      name: 'PrivacyAgreement',
      meta: { title: '壹词万物隐私政策' },
      component: () => import('@/pages/agreement/privacy.vue'),
    },
    {
      path: '/recharge',
      name: 'Recharge',
      component: () => import('@/pages/recharge/index.vue'),
      meta: { title: '充值', activeIndex: 2 },
    },
    {
      path: '/recharge/success',
      name: 'RechargeSuccess',
      component: () => import('@/pages/recharge/success.vue'),
      meta: { title: '充值完成', activeIndex: 2 },
    },
    {
      path: '/coreatorSpecification',
      name: 'CoreatorSpecification',
      meta: { title: '壹词万物创作者社区规范' },
      component: () => import('@/pages/agreement/index.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/pages/common/comUnfound.vue'),
      meta: { title: '404' },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ];
}

export default routes;
