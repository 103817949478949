import BrowserLogger from '@arms/js-sdk';
import { allLsObj } from '@/common/utils/ls';
// eslint-disable-next-line no-underscore-dangle
const __bl = BrowserLogger.singleton({
  pid: process.env.NODE_ENV === 'production' ? 'a7vxty3y6v@6aebac8899dc579' : 'a7vxty3y6v@0d7962cdb79b8b6',
  appType: 'web',
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
  sendResource: true,
  enableLinkTrace: true,
  behavior: true,
  enableSPA: true,
  useFmp: true,
});
__bl.setConfig({
  setUsername() {
    let userId = allLsObj.global.userId.get();
    userId = userId ? window.btoa(userId) : null;
    return userId;
  },
});
